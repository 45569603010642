import React from 'react';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import NextLink from 'next/link';

import { HomeDocument, HomeQuery, CategoryObjectType } from '../../generated/graphql';
import PageLayout from '../components/blocks/page-layout';
import Products from '../components/blocks/products';
import Title from '../components/elements/title';
import graphqlClient from '../utils/graphql-client';
import { captureException } from './_app';
import { SHOP_ID } from '../constants';
import { Image } from '../components/elements/image';
import { ImageFitMode } from '../utils/imagekit';
import Categories from '../components/blocks/categories';
import { sortProductsByAvailability } from '../utils/products';
import { useTranslate } from '../hooks/useTranslate';
import { useLocale } from '../hooks/useLocale';

type ShopData = HomeQuery['shop'];
type ProductList = HomeQuery['allProductsInStore'];

type Props = {
  shop?: ShopData;
  newArrivals?: ProductList;
  categories: Array<CategoryObjectType>;
  error?: Error;
};

export default function Page(props: Props) {
  const { newArrivals, categories, shop } = props;
  const translate = useTranslate();
  const locale = useLocale();

  return (
    <PageLayout title="Nieuwste collectie en aanbiedingen" image={shop?.bannerImageUrl}>
      {!!shop?.bannerImageUrl && (
        <div className="pb-4">
          <a href={`/${locale}/products?isOutlet=1`} className="w-full flex justify-center">
            <Image
              url={shop.bannerImageUrl}
              width={1000}
              fitMode={ImageFitMode.Crop}
              sizes="1000px"
              alt="Gypsy mode"
              ar={{
                width: 1,
                height: 0.4,
              }}
            />
          </a>
        </div>
      )}

      <Categories categories={categories} />

      {(newArrivals?.length || 0) > 0 && (
        <>
          <Title>New Arrivals</Title>
          {/* @ts-ignore */}
          <Products products={newArrivals || []} />
          <div className="my-8">
            <NextLink
              href="/products"
              className="p-4 text-xl flex justify-center items-center text-white font-medium bg-cambridge-blue-200 hover:ring-2 focus:ring-4"
            >
              {translate('viewAllClothing')}
            </NextLink>
          </div>
        </>
      )}
    </PageLayout>
  );
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext): Promise<GetServerSidePropsResult<Props>> => {
  const {res} = ctx;
  let newArrivals;
  let shop;
  let categories: Array<CategoryObjectType> = [];
  let error = null;

  try {
    let res = await graphqlClient.request<HomeQuery>(HomeDocument, {
      shopId: SHOP_ID,
    });

    newArrivals = sortProductsByAvailability(res.allProductsInStore).slice(0, 8);
    // @ts-ignore
    categories = res.listCategories || [];
    shop = res.shop;
  } catch (err) {
    console.error(err);
    captureException(err);
    error = err;
  }

  res.setHeader(
    'Cache-Control',
    'public, s-maxage=30, stale-while-revalidate=59'
  )

  return {
    props: {
      newArrivals,
      categories,
      shop,
      // @ts-ignore
      error,
    },
  };
}

export const runtime = 'experimental-edge';
