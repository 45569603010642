import React from 'react';
import NextLink from 'next/link';

import { CategoryObjectType } from '../../../generated/graphql';
import { Image } from '../elements/image';
import { ImageFitMode } from '../../utils/imagekit';
import { useLocale } from '../../hooks/useLocale';

type Props = {
  categories: Array<CategoryObjectType>;
};

export default function Categories(props: Props) {
  const { categories } = props;
  const locale = useLocale();

  return (
    <div className="my-8 grid grid-cols-1 md:grid-cols-2 gap-8">
      {categories.map((category) => {
        return (
          <div key={category._id} className="relative">
            <NextLink
              href={`/products?category=${category._id}`}
              as={`/products?category=${category._id}`}
              className="block"
            >
              <Image
                url={category.picture || '#'}
                width={800}
                fitMode={ImageFitMode.Crop}
                sizes="800px"
                alt="Gypsy mode"
                ar={{
                  width: 1,
                  height: 0.6,
                }}
              />
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-end">
                <div className="bg-desert-sand text-white text-lg text-center font-medium font-sans px-4 py-2 w-full">
                  {category.name[locale]}
                </div>
              </div>
            </NextLink>
          </div>
        );
      })}
    </div>
  );
}
